import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import _objectSpread from "E:/work/coding/ruoyi-vue-pro-master/defi/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import NetworkConfig from '@/config/network';
import axios from "axios";
import { mapActions } from "vuex";
export default {
  data: function data() {
    return {
      inputUsdtAmount: 0,
      netWorkList: Object.values(NetworkConfig)
    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions(['setUserInfo'])), {}, {
    setInputUsdtMax: function setInputUsdtMax() {
      this.inputUsdtAmount = this.userInfo.usdtPlatAmount;
    },
    invite: function invite() {
      this.$router.push("invite");
    },
    activity: function activity() {
      this.$router.push("activity");
    },
    register: function register() {
      var _this = this;

      var url = "/defi/site/register";
      var params = {
        address: this.currentAccount,
        chain: this.defaultNetworkConfig.chainName
      };
      axios.post(url, params).then(function (res) {
        _this.setUserInfo(res.data.data);
      });
    },
    withdraw: function withdraw() {
      if (!this.currentAccount) {
        _Notify({
          type: 'danger',
          message: t('retConnect')
        });

        return;
      }

      var url = "/defi/site/withdraw";
      var params = {
        chain: this.defaultNetworkConfig.chainName,
        address: this.currentAccount,
        inputAmount: this.inputUsdtAmount
      };

      if (Number(params.inputAmount) <= 0) {
        return;
      }

      axios.post(url, params).then(function (res) {
        if (res.data.code === 0) {
          _Notify({
            type: 'success',
            message: t('successful')
          });
        }

        if (res.data.code === 5001) {
          _Notify({
            type: 'danger',
            message: t('alreadyPending')
          });
        }
      });
    },
    income: function income() {
      if (!this.currentAccount) {
        return;
      }

      this.$router.push("income");
    },
    teams: function teams() {
      if (!this.currentAccount) {
        return;
      }

      this.$router.push("teams");
    },
    withdrawRecords: function withdrawRecords() {
      if (!this.currentAccount) {
        return;
      }

      this.$router.push("withdraws");
    }
  }),
  computed: {
    currentAccount: function currentAccount() {
      var currentAccount = this.$store.state.currentAccount;

      if (currentAccount) {
        this.$emit('connected', currentAccount);
      }

      return currentAccount;
    },
    defaultNetworkConfig: {
      get: function get() {
        return this.netWorkList.find(function (r) {
          return r.default;
        });
      }
    },
    global: {
      get: function get() {
        return this.$store.state.global;
      }
    },
    userInfo: {
      get: function get() {
        return this.$store.state.userInfo;
      }
    }
  }
};